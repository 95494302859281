<template>
  <div>
    <v-dialog v-bind:fullscreen="$vuetify.breakpoint.mdAndDown" scrollable
              v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="600">
      <v-card>
        <v-img gradient="to bottom, rgba(255, 255, 255, .1), rgba(255, 255, 255, .9)"
               max-height="240"
               v-bind:src="foodTruck.imageId != null ? attachmentUrl(foodTruck.imageId) : defaultImageUrl">
          <v-toolbar color="transparent"
                     dark
                     flat>
            <v-spacer></v-spacer>
            <v-btn icon
                   light
                   v-on:click="$emit('input', false)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <div class="px-4" style="margin-top: 88px;">
            <v-row align="center">
              <v-col cols="auto">
                <v-avatar size="72"
                          v-bind:color="foodTruck.logoId == null ? 'primary' : null">
                  <v-img v-bind:src="attachmentUrl(foodTruck.logoId)"
                        v-if="foodTruck.logoId != null">
                  </v-img>
                  <v-icon color="white"
                          dark
                          v-else>
                    mdi-truck
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col>
                <p class="black--text mb-0 text-h5">
                  {{ foodTruck.title }}
                </p>
                <p class="mb-0 text-subtitle-2">
                  <span>
                    <v-icon class="mr-1"
                            x-small>
                      {{ `mdi-${categoryIcon(foodTruck.categoryId)}` }}
                    </v-icon>
                    {{ categoryTitle(foodTruck.categoryId) }}
                  </span>

                  <span class="ml-2"
                        v-if="foodTruck.secondaryCategoryId != null">
                    <v-icon class="mr-1"
                            x-small>
                      {{ `mdi-${categoryIcon(foodTruck.secondaryCategoryId)}` }}
                    </v-icon>
                    {{ categoryTitle(foodTruck.secondaryCategoryId) }}
                  </span>
                </p>
              </v-col>
            </v-row>
          </div>

        </v-img>



        <v-card-text class="pa-0">
          <v-tabs-items v-model="tab">
            <v-tab-item value="details">
              <!-- DETAILS -->
              <!-- ------- -->
              <div class="black--text px-4 pt-4">
                <v-row dense>
                  <v-col cols="auto"
                         v-bind:key="slot.id"
                         v-for="slot in todaysSlots">
                    <v-card outlined>
                      <v-card-text>
                        <p class="display-1 mb-0 accent--text">{{  selectedDate | formatDate('D.M.') }}</p>
                        <p class="text-h6 mb-2 accent--text">{{ slot.from }}–{{ slot.to }}</p>
                        <p class="mb-0">
                          {{  slot.streetAddress }}<br>
                          {{  slot.postalCode }} {{  slot.city }}<br>
                          {{  slot.country }}
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </div>



              <div class="black--text px-4 pt-4"
                          v-if="description != null && description.length !== 0">
                <p v-html="description"></p>
              </div>

              <v-list class="pt-0"
                      two-line>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-map-marker</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ `${foodTruck.streetAddress}, ${foodTruck.postalCode} ${foodTruck.city}` }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('location') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="foodTruck.phone != null && foodTruck.phone.length !== 0">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ foodTruck.phone }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('phone') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="foodTruck.specialties != null && foodTruck.specialties.length !== 0">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-star</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ foodTruck.specialties }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('specialties') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-btn block
                     class="mb-2"
                     color="primary"
                     target="_blank"
                     text
                     v-bind:href="attachmentUrl(foodTruck.menuId)"
                     v-if="foodTruck.menuId != null">
                <v-icon left>mdi-food-drumstick-outline</v-icon>
                {{ $t('showMenu') }}
              </v-btn>

              <v-btn block
                     class="mb-2"
                     color="primary"
                     text
                     v-bind:href="`tel:${foodTruck.phone}`"
                     v-if="foodTruck.phone != null && foodTruck.phone.length !== 0">
                <v-icon left>mdi-phone-outline</v-icon>
                {{ $t('orderNow') }}
              </v-btn>

              <v-btn block
                     class="mb-2"
                     color="primary"
                     text
                     v-if="canShare"
                     v-on:click="share">
                <v-icon left>mdi-share-outline</v-icon>
                {{ $t('share') }}
              </v-btn>

              <v-btn block
                     class="mb-2"
                     color="primary"
                     text
                     v-bind:href="`mailto:${foodTruck.email}`"
                     v-if="foodTruck.email != null && foodTruck.email.length !== 0">
                <v-icon left>mdi-email-outline</v-icon>
                {{ $t('cateringRequest') }}
              </v-btn>

              <v-btn block
                     class="mb-2"
                     color="primary"
                     target="_blank"
                     text
                     v-bind:href="foodTruck.instagram"
                     v-if="foodTruck.instagram != null && foodTruck.instagram.length !== 0">
                <v-icon left>mdi-instagram</v-icon>
                {{ $t('instagram') }}
              </v-btn>

              <v-btn block
                     class="mb-2"
                     color="primary"
                     target="_blank"
                     text
                     v-bind:href="foodTruck.shop"
                     v-if="foodTruck.shop != null && foodTruck.shop.length !== 0">
                <v-icon left>mdi-cart-outline</v-icon>
                {{ $t('onlineShop') }}
              </v-btn>

              <v-btn block
                     color="accent"
                     target="_blank"
                     tile
                     v-bind:href="foodTruck.web"
                     v-if="foodTruck.web != null && foodTruck.web.length !== 0"
                     x-large>
                {{ $t('goToWebSite') }}
              </v-btn>
            </v-tab-item>
            <v-tab-item value="hours">
              <!-- HOURS -->
              <!-- ----- -->
              <v-select dark
                        flat
                        hide-details="auto"
                        solo
                        v-bind:items="weekItems"
                        v-model="selectedWeek" />

              <template v-for="dayOfWeekGroup in timeSlotGroups">
                <v-subheader v-bind:class="{ 'accent': isSelectedDayOfWeek(dayOfWeekGroup.dayOfWeek), 'font-weight-bold': isCurrentDayOfWeek(dayOfWeekGroup.dayOfWeek), 'white--text': isSelectedDayOfWeek(dayOfWeekGroup.dayOfWeek) }"
                            v-bind:key="`${dayOfWeekGroup.dayOfWeek}-subheader`">
                  {{ isCurrentDayOfWeek(dayOfWeekGroup.dayOfWeek) ? $t('today') : $t(daysOfWeekLabel(dayOfWeekGroup.dayOfWeek)) }}
                </v-subheader>
                <div v-bind:class="{ 'accent': isSelectedDayOfWeek(dayOfWeekGroup.dayOfWeek), 'px-4 pb-4': true, 'white--text': isSelectedDayOfWeek(dayOfWeekGroup.dayOfWeek) }"
                            v-bind:key="`${dayOfWeekGroup.dayOfWeek}-card_text`">
                  <v-row align="center"
                        v-bind:key="timeSlot.id"
                        v-for="timeSlot in dayOfWeekGroup.timeSlots">
                    <v-col>
                      <strong>{{ timeSlot.from }}–{{ timeSlot.to }}</strong>
                    </v-col>
                    <v-col class="text-right">
                      <template v-if="timeSlot.comment != null && timeSlot.comment.length !== 0">
                        <strong>{{ timeSlot.comment }}</strong><br>
                      </template>
                      {{ timeSlot.streetAddress }}<br>
                      {{ timeSlot.postalCode }} {{ timeSlot.city }}<br>
                      {{ timeSlot.country }}
                    </v-col>
                    <v-col cols="auto">
                      <v-btn icon
                            v-bind:color="isSelectedDayOfWeek(dayOfWeekGroup.dayOfWeek) ? 'white' : 'accent'"
                            v-bind:href="mapUrl(timeSlot)">
                        <v-icon>mdi-map-marker</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <span class="deep-orange--text"
                        v-if="dayOfWeekGroup.timeSlots.length === 0">
                    {{ $t('closed') }}
                  </span>
                </div>
                <v-divider v-bind:key="`${dayOfWeekGroup.dayOfWeek}-divider`" />
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-bottom-navigation v-model="tab" background-color="secondary" dark>
          <v-btn value="details">
            <span>{{ $t('details' )}}</span>

            <v-icon>mdi-information</v-icon>
          </v-btn>

          <v-btn value="hours" v-if="!foodTruck.partner">
            <span>{{ $t('hours') }}</span>

            <v-icon>mdi-clock</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import moment from 'moment'

  import settings from '../settings'

  export default {
    computed: {
      canShare() {
        return navigator.share != null
      },
      description() {
        if (this.foodTruck?.description == null) {
          return ''
        }

        return this.foodTruck.description.replace(/\n/g, '<br>')
      },
      isEvenWeek() {
        return this.selectedWeek % 2 === 0
      },
      isOddWeek() {
        return this.selectedWeek % 2 === 1
      },
      todaysSlots() {
        if (this.foodTruck?.timeSlots == null) {
          return []
        }

        const dayOfWeek = moment(this.selectedDate).isoWeekday()
        const week = moment(this.selectedDate).isoWeek()
        const isEvenWeek = week % 2 === 0
        const isOddWeek = week % 2 === 1

        const timeSlots = this.foodTruck.timeSlots.filter(t => !t.disabled && t.dayOfWeek === dayOfWeek && (t.evenWeeks && isEvenWeek || t.oddWeeks && isOddWeek))

        return timeSlots.sort((a, b) => a.from.localeCompare(b.from))
      },
      timeSlotGroups() {
        if (this.foodTruck?.timeSlots == null) {
          return []
        }

        return this.foodTruck.timeSlots.reduce((result, current) => {
          if (!(!current.disabled && current.evenWeeks && this.isEvenWeek || !current.disabled && current.oddWeeks && this.isOddWeek)) {
            return result
          }

          const index = result.findIndex(g => g.dayOfWeek === current.dayOfWeek)

          if (index !== -1) {
            result[index].timeSlots.push(current)

            result[index].timeSlots = result[index].timeSlots.sort((a, b) => a.from.localeCompare(b.from))
          }

          return result
        }, JSON.parse(JSON.stringify(this.timeSlotsDefault))).sort((a, b) => a.dayOfWeek - b.dayOfWeek)
      },
      weekItems() {
        return Array.from([0, 1], w => ({
          text: this.$t('cw', { week: moment().isoWeek() + w }),
          value: moment().isoWeek() + w
        }))
      },
      ...mapGetters([
        'attachmentUrl',
        'categoryIcon',
        'categoryTitle',
        'daysOfWeekLabel'
      ])
    },
    created() {
      this.selectedWeek = moment().isoWeek()
    },
    data: () => ({
      defaultImageUrl: '/images/food-truck-default-image.jpg',
      selectedWeek: null,
      tab: null,
      timeSlotsDefault: [
        {
          dayOfWeek: 1,
          timeSlots: []
        },
        {
          dayOfWeek: 2,
          timeSlots: []
        },
        {
          dayOfWeek: 3,
          timeSlots: []
        },
        {
          dayOfWeek: 4,
          timeSlots: []
        },
        {
          dayOfWeek: 5,
          timeSlots: []
        },
        {
          dayOfWeek: 6,
          timeSlots: []
        },
        {
          dayOfWeek: 7,
          timeSlots: []
        }
      ]
    }),
    methods: {
      isCurrentDayOfWeek(dayOfWeek) {
        if (this.selectedWeek != moment().isoWeek()) {
          return false
        }

        return moment().day() === dayOfWeek
      },
      isSelectedDayOfWeek(dayOfWeek) {
        if (this.selectedDate == null) {
          return false
        }

        if (this.selectedWeek != moment(this.selectedDate).isoWeek()) {
          return false
        }

        return moment(this.selectedDate).day() === dayOfWeek
      },
      mapUrl(timeSlot) {
        let baseUrl = 'http://maps.google.com/maps'

        if (navigator.platform.indexOf('iPad') != -1 ||
          navigator.platform.indexOf('iPhone') != -1 ||
          navigator.platform.indexOf('iPod') != -1 ||
          navigator.platform.indexOf('Mac') != -1) {
          baseUrl = 'maps://maps.google.com/maps'
        }

        return `${baseUrl}?daddr=${timeSlot.latitude},${timeSlot.longitude}&amp;ll=`
      },
      async open() {
        if (this.selectedDate != null) {
          this.selectedWeek = moment(this.selectedDate).isoWeek()
        }

        this.$emit('input', true)
      },
      async share() {
        await navigator.share({
          title: this.foodTruck.title,
          text: this.foodTruck.description,
          url: `${settings.baseUrl}/?foodTruckId=${this.foodTruck.id}`,
        })
      }
    },
    name: 'FoodTruckDialog',
    props: {
      foodTruck: Object,
      selectedDate: String,
      value: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "cateringRequest": "Catering-Anfrage",
      "closed": "Geschlossen",
      "cw": "KW {week}",
      "details": "Details",
      "goToWebSite": "Zur Website",
      "hours": "Öffnungszeiten",
      "instagram": "Instagram",
      "location": "Base",
      "onlineShop": "Kooperationsinfo",
      "orderNow": "Jetzt bestellen",
      "phone": "Telefon",
      "share": "Teilen",
      "showMenu": "Speisekarte anzeigen",
      "specialties": "Special Facts",
      "today": "Heute"
    },
    "en": {
      "cateringRequest": "Catering request",
      "closed": "Closed",
      "cw": "CW {week}",
      "details": "Details",
      "goToWebSite": "Go to website",
      "hours": "Hours",
      "instagram": "Instagram",
      "location": "Location",
      "onlineShop": "Cooperations",
      "orderNow": "Order now",
      "phone": "Phone",
      "share": "Share",
      "showMenu": "Show menu",
      "specialties": "Specialties",
      "today": "Today"
    }
  }
</i18n>