<template>
  <div v-bind:class="{ 'map-display': true, 'map-display--mobile': $vuetify.breakpoint.smAndDown }"
       ref="map">
  </div>
</template>

<script>
  export default {
    data: () => ({
      map: null,
      mapAnnotations: []
    }),
    methods: {
      deselect() {
        this.map.selectedAnnotation = null
      },
      setRegion(latitude, longitude, latitudeDelta, longitudeDelta, animate) {
        this.map.setRegionAnimated(new mapkit.CoordinateRegion(new mapkit.Coordinate(latitude, longitude), new mapkit.CoordinateSpan(latitudeDelta, longitudeDelta)), animate)
      },
      updateAnnotations() {
        this.map.removeAnnotations(this.mapAnnotations)

        this.mapAnnotations = []

        this.annotations.forEach(a => {
          const annotation = new mapkit.ImageAnnotation(new mapkit.Coordinate(a.latitude, a.longitude), {
            collisionMode: mapkit.Annotation.CollisionMode.Circle,
            clusteringIdentifier: a.clusteringIdentifier,
            title: a.title,
            animates: false,
            data: {
              id: a.id
            },
            size: {
              width: 48,
              height: 48
            },
            url: { 1: a.logoUrl ? a.logoUrl : '/images/marker-96.png' },
            anchorOffset: new DOMPoint(0, -24)
          })

          /*annotation.addEventListener('select', event => {
            const id = event?.target?.data?.id

            if (id != null) {
              this.$emit('annotation-selected', { id })
            }
          })*/

          this.mapAnnotations.push(annotation)
        })

        this.map.addAnnotations(this.mapAnnotations)
      },
      zoomToFit(annotations) {
        const northLatitude = Math.max(...annotations.map(a => a.coordinate.latitude))
        const southLatitude = Math.min(...annotations.map(a => a.coordinate.latitude))
        const eastLongitude = Math.max(...annotations.map(a => a.coordinate.longitude))
        const westLongitude = Math.min(...annotations.map(a => a.coordinate.longitude))

        const boundingRegion = new mapkit.BoundingRegion(northLatitude, eastLongitude, southLatitude, westLongitude)
        const coordinateRegion = boundingRegion.toCoordinateRegion()

        this.map.setRegionAnimated(coordinateRegion)
      }
    },
    mounted() {
      this.map = new mapkit.Map(this.$refs.map, {
        padding: new mapkit.Padding(
          88, 24, 24, 24
        ),
        region: new mapkit.CoordinateRegion(new mapkit.Coordinate(51.6246571, 9.8255757), new mapkit.CoordinateSpan(10, 10)),
        showsCompass: mapkit.FeatureVisibility.Hidden,
        tintColor: this.$vuetify.theme.currentTheme.primary
      })

      this.map.addEventListener('select', event => {
        const annotation = event?.annotation

        if (annotation == null) {
          return
        }

        const id = annotation.data?.id

        if (id != null) {
          this.$emit('annotation-selected', { id })
        } else if (annotation.memberAnnotations != null) {
          this.zoomToFit(annotation.memberAnnotations)
          //this.setRegion(annotation.coordinate.latitude, annotation.coordinate.longitude, 0.5, 0.5)
        }
      })

      this.updateAnnotations()
    },
    name: 'MapComponent',
    props: {
      annotations: Array
    },
    watch: {
      annotations(newAnnotations, oldAnnotations) {
        this.updateAnnotations()
      }
    }
  }
</script>

<style scoped>
  .map-display {
    height: calc(100vh - 64px);
  }

  .map-display--mobile {
    height: calc(100vh - 56px);
  }
</style>