<template>
  <div>
    <v-card>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form">
        <v-card-text>
          <v-text-field class="mb-6"
                        filled
                        hide-details="auto"
                        v-bind:disabled="!hasRole('FoodTruckFinderAdministrator')"
                        v-bind:label="$t('title')"
                        v-bind:rules="[ rules.required ]"
                        v-model="foodTruck.title" />
          <v-textarea class="mb-6"
                      filled
                      hide-details="auto"
                      v-bind:label="$t('description')"
                      v-model="foodTruck.description" />
          <v-text-field class="mb-6"
                        filled
                        hide-details="auto"
                        v-bind:label="$t('specialties')"
                        v-model="foodTruck.specialties" />
          <v-select class="mb-6"
                    filled
                    hide-details="auto"
                    v-bind:items="categoryItems"
                    v-bind:label="$t('category')"
                    v-bind:rules="[ rules.required ]"
                    v-model="foodTruck.categoryId" />
          <v-select class="mb-6"
                    clearable
                    filled
                    hide-details="auto"
                    v-bind:items="categoryItems"
                    v-bind:label="$t('secondaryCategory')"
                    v-model="foodTruck.secondaryCategoryId" />
          <v-checkbox hide-details="auto"
                      v-bind:disabled="!hasRole('FoodTruckFinderAdministrator')"
                      v-bind:label="$t('highlightFoodTruck')"
                      v-model="foodTruck.highlight" />
          <v-checkbox hide-details="auto"
                      v-bind:disabled="!hasRole('FoodTruckFinderAdministrator')"
                      v-bind:label="$t('setAsPartner')"
                      v-model="foodTruck.partner" />
        </v-card-text>
        <v-divider />
        <v-subheader class="font-weight-bold">{{ $t('address') }}</v-subheader>
        <v-card-text>
          <v-text-field class="mb-6"
                        filled
                        hide-details="auto"
                        v-bind:label="$t('region')"
                        v-model="foodTruck.regionGroup" />
          <v-text-field class="mb-6"
                        filled
                        hide-details="auto"
                        v-bind:label="$t('streetAddress')"
                        v-bind:rules="[ rules.required ]"
                        v-model="foodTruck.streetAddress" />
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field filled
                            hide-details="auto"
                            v-bind:label="$t('postalCode')"
                            v-bind:rules="[ rules.required ]"
                            v-model="foodTruck.postalCode" />
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field filled
                            hide-details="auto"
                            v-bind:label="$t('city')"
                            v-bind:rules="[ rules.required ]"
                            v-model="foodTruck.city" />
            </v-col>
            </v-row>
            <v-row>
            <v-col cols="12">
              <v-text-field filled
                            hide-details="auto"
                            v-bind:label="$t('country')"
                            v-model="foodTruck.country" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-subheader class="font-weight-bold">{{ $t('communication') }}</v-subheader>
        <v-card-text>
          <v-text-field class="mb-6"
                        filled
                        hide-details="auto"
                        v-bind:label="$t('web')"
                        v-model="foodTruck.web" />
          <v-text-field class="mb-6"
                        filled
                        hide-details="auto"
                        v-bind:label="$t('email')"
                        v-model="foodTruck.email" />
          <v-text-field class="mb-6"
                        filled
                        hide-details="auto"
                        v-bind:label="$t('phone')"
                        v-model="foodTruck.phone" />
          <v-text-field class="mb-6"
                        filled
                        hide-details="auto"
                        v-bind:label="$t('instagram')"
                        v-model="foodTruck.instagram" />
          <v-text-field class="mb-6"
                        filled
                        hide-details="auto"
                        v-bind:label="$t('facebook')"
                        v-model="foodTruck.facebook" />
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('onlineShop')"
                        v-model="foodTruck.shop" />
        </v-card-text>
        <v-divider />
        <v-subheader class="font-weight-bold">{{ $t('files') }}</v-subheader>
        <v-card-text>
          <attachment-field accept="image/*"
                            class="mb-6"
                            show-image
                            v-bind:label="$t('image')"
                            v-bind:max-size="4096"
                            v-model="foodTruck.imageId" />
          <attachment-field accept="image/png"
                            class="mb-6"
                            v-bind:label="$t('logo')"
                            v-bind:max-size="512"
                            v-model="foodTruck.logoId" />
          <attachment-field accept="application/pdf"
                            v-bind:label="$t('menu')"
                            v-bind:max-size="4096"
                            v-model="foodTruck.menuId" />
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import AttachmentField from '@/components/common/AttachmentField'

  export default {
    components: {
      AttachmentField
    },
    computed: {
      categoryItems() {
        return this.categories.map(c => ({
          text: c.title,
          value: c.id
        }))
      },
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      ...mapGetters({
        categories: 'categories',
        hasRole: 'auth/hasRole'
      })
    },
    name: 'FoodTruckDetailsCard',
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    props: {
      foodTruck: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "address": "Adresse",
      "category": "Kategorie",
      "city": "Ort",
      "communication": "Kontakt",
      "description": "Beschreibung",
      "details": "Details",
      "email": "E-Mail-Adresse",
      "files": "Dateien",
      "highlightFoodTruck": "Foodtruck hervorheben",
      "image": "Bild",
      "instagram": "Instagram",
      "logo": "Logo",
      "menu": "Speisekarte",
      "onlineShop": "Kooperationsinfo",
      "phone": "Telefon",
      "postalCode": "PLZ",
      "region": "Region",
      "secondaryCategory": "Zweitkategorie",
      "specialties": "Special Facts",
      "streetAddress": "Adresse",
      "title": "Titel",
      "web": "Webseite",
      "setAsPartner": "Als Partner setzen",
      "country": "Land"
    },
    "en": {
      "address": "Address",
      "category": "Category",
      "city": "City",
      "communication": "Communication",
      "description": "Description",
      "details": "Details",
      "email": "Email",
      "files": "Files",
      "highlightFoodTruck": "Highlight food truck",
      "image": "Image",
      "instagram": "Instagram",
      "logo": "Logo",
      "menu": "Speisekarte",
      "onlineShop": "Cooperations",
      "phone": "Phone",
      "postalCode": "Postal code",
      "region": "Region",
      "secondaryCategory": "Secondary category",
      "specialties": "Specialties",
      "streetAddress": "Street adress",
      "title": "Title",
      "web": "Web",
      "setAsPartner": "Set as partner",
      "country": "Country"
    }
  }
</i18n>